@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-behavior: smooth;
}
.primary-filter {
  filter: brightness(0) saturate(100%) invert(8%) sepia(85%) saturate(3737%) hue-rotate(263deg)
    brightness(61%) contrast(116%);
}
.accent-dark-filter {
  filter: brightness(0) saturate(100%) invert(22%) sepia(49%) saturate(3133%) hue-rotate(254deg)
    brightness(99%) contrast(87%);
}
.accent-filter {
  filter: invert(48%) sepia(70%) saturate(542%) hue-rotate(212deg) brightness(101%) contrast(102%);
}
.disabled-filter {
  filter: brightness(0) saturate(100%) invert(75%) sepia(0%) saturate(0%) hue-rotate(208deg)
    brightness(96%) contrast(85%);
}
.white-filter {
  filter: brightness(0) saturate(100%) invert(100%) sepia(99%) saturate(0%) hue-rotate(230deg)
    brightness(105%) contrast(100%);
}
.booking-card-chevron-filter {
  filter: brightness(0) saturate(100%) invert(61%) sepia(22%) saturate(0%) hue-rotate(223deg)
    brightness(96%) contrast(83%);
}
.no-select {
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}
.no-scroll {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  -webkit-scrollbar-width: none;
  -moz-scrollbar-width: none;
  -ms-scrollbar-width: none;
}

.no-scroll::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

body {
  @apply text-primary;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: 550;
  line-height: 1.3;
}
h1 {
  font-size: 3.157rem;
}

h2 {
  font-size: 2.369rem;
}

h3 {
  font-size: 1.777rem;
}

h4 {
  font-size: 1.333rem;
}

h5 {
  font-size: 1.1rem;
}

h6 {
  font-size: 1rem;
}

* {
  border-color: #e5e7eb;
}
.policy {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}
.policy p {
  margin: 15px 0;
}

.policy a {
  text-decoration: underline;
}
.hover-border {
  @apply cursor-pointer lg:hover:bg-[#E3D0FE] border-transparent transition-colors duration-[50ms] ease-in sm:border-2;
}
@layer base {
  .removeStepper::-webkit-inner-spin-button,
  .removeStepper::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.pac-container {
  @apply font-figtree;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 5px 5px 35px 0px rgba(165, 175, 191, 0.5);
  transform: translateY(13px);
}
.pac-container::after,
.pac-icon-marker {
  display: none !important;
}
.pac-item {
  cursor: pointer;
  border-radius: 8px;
  padding: 12px;
  border-top: none !important;
  @apply text-contentTertiary;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 150% */
  color: #190041;
}
.pac-item:hover {
  background: #eaddff;
}
.pac-item-query {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
@media screen and (max-width: 600px) {
  .pac-container {
    box-shadow: none;
    border-top: none;
  }
}
@keyframes bottom-full-to-0 {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
@media screen and (max-width: 600px) {
  .bottom-up {
    animation: bottom-full-to-0 400ms ease-in-out;
  }
}
[data-testid='document-pane'] > div > div {
  overflow: scroll;
}
::selection {
  background: #dacbff;
}
.snap-scroll {
  -webkit-overflow-scrolling: touch;
  -moz-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
}
.snap-scroll-item {
  -webkit-scroll-snap-align: start;
  -moz-scroll-snap-align: start;
  -ms-scroll-snap-align: start;
  scroll-snap-align: start;
  -webkit-scroll-snap-stop: always;
  -moz-scroll-snap-stop: always;
  -ms-scroll-snap-stop: always;
  scroll-snap-stop: always;
}
@keyframes rotate360 {
  to {
    transform: rotate(360deg);
  }
}
.spin {
  animation: rotate360 1.2s linear infinite;
  animation-play-state: paused;
}
.play-animation {
  animation-play-state: running; /* Run on hover! */
}
.pill-hover {
  padding: 17px 30px;
}
.pill-hover:hover {
  background: #eaddff;
  border-radius: 100px;
  transition: background-color 200ms ease-in;
}
.line-height-normal {
  line-height: normal;
}
ul .primary-bullet::before {
  content: ''; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  background-color: #9e7bff; /* Change the color */

  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 12px; /* Also needed for space (tweak if needed) */
  height: 12px; /* Also needed for space (tweak if needed) */
  border-radius: 50%;
  margin-right: 10px;
}
